import axios from 'axios';

// API põhiteekond
const API_BASE_URL = 'https://helikon.technology:5000';

// ** Uuringute API-d **

// Uuringute laadimine
export const fetchStudies = (token) => {
    return axios.get(`${API_BASE_URL}/studies`, {
        headers: { Authorization: `Bearer ${token}` },
    });
};

// Uuringu üleslaadimine
export const uploadStudy = (token, formData) => {
    return axios.post(`${API_BASE_URL}/studies`, formData, {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
        },
    });
};

// Uuringu kustutamine
export const deleteStudy = (token, studyId) => {
    return axios.delete(`${API_BASE_URL}/studies/${studyId}`, {
        headers: { Authorization: `Bearer ${token}` },
    });
};

// ** Failide API-d **

// Faili allalaadimine
export const downloadFile = (token, userId, filename) => {
    const fileUrl = `${API_BASE_URL}/files/${userId}/${filename}`;
    return axios.get(fileUrl, {
        headers: { Authorization: `Bearer ${token}` },
        responseType: 'blob', // Faili laadimiseks
    });
};

// ** Admini API-d **

// Kõikide uuringute laadimine adminile
export const fetchAllStudies = (token) => {
    return axios.get(`${API_BASE_URL}/admin/studies`, {
        headers: { Authorization: `Bearer ${token}` },
    });
};

// Admini API uuringu kustutamiseks
export const adminDeleteStudy = (token, studyId) => {
    return axios.delete(`${API_BASE_URL}/admin/studies/${studyId}`, {
        headers: { Authorization: `Bearer ${token}` },
    });
};

// Kasutajate laadimine adminile
export const fetchUsers = (token) => {
    return axios.get(`${API_BASE_URL}/admin/users`, {
        headers: { Authorization: `Bearer ${token}` },
    });
};

// Uue kasutaja lisamine
export const addUser = (token, userData) => {
    return axios.post(`${API_BASE_URL}/admin/users`, userData, {
        headers: { Authorization: `Bearer ${token}` },
    });
};

// Kasutaja kustutamine adminina
export const deleteUser = (token, userId) => {
    return axios.delete(`${API_BASE_URL}/admin/users/${userId}`, {
        headers: { Authorization: `Bearer ${token}` },
    });
};

// ** Tokeni valideerimine **

// Tokeni valideerimine ja kasutaja andmete laadimine
export const validateToken = (token) => {
    return axios.get(`${API_BASE_URL}/validate-token`, {
        headers: { Authorization: `Bearer ${token}` },
    });
};
