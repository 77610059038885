import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './CSS/AdminPanel.css'; // CSS import

function AdminPanel({ token }) {
    const [allStudies, setAllStudies] = useState([]);
    const [responseMessage, setResponseMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [responseFile, setResponseFile] = useState(null);
    const [uploadingFile, setUploadingFile] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedStudyType, setSelectedStudyType] = useState('Kõik');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const studyTypes = ['Kõik', 'Vererõhu holter', 'Rütmi holter', 'MRT uuring', 'Ultraheli uuring', 'Ehhokardiogramm'];

    const fetchAllStudies = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get('https://helikon.technology:5000/admin/studies', {
                headers: { Authorization: `Bearer ${token}` },
            });
            const sortedStudies = response.data.sort(
                (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
            );
            setAllStudies(
                sortedStudies.map((study) => ({
                    ...study,
                    files: Array.isArray(study.files) ? study.files : (study.files || '').split(','),
                }))
            );
        } catch (error) {
            console.error('Uuringute laadimine ebaõnnestus:', error);
            setResponseMessage(
                error.response?.data?.message || 'Uuringute laadimine ebaõnnestus.'
            );
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchAllStudies();
    }, [token]);

    const handleUploadResponseFile = async (e, studyId) => {
        e.preventDefault();

        if (!responseFile) {
            setResponseMessage('Palun vali fail.');
            return;
        }

        const formData = new FormData();
        formData.append('responseFile', responseFile);

        try {
            setUploadingFile(studyId);
            const response = await axios.post(
                `https://helikon.technology:5000/admin/studies/${studyId}/response`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            setResponseMessage(response.data.message);
            fetchAllStudies();
        } catch (error) {
            console.error('Vastusefaili üleslaadimine ebaõnnestus:', error);
            setResponseMessage(
                error.response?.data?.message || 'Vastusefaili üleslaadimine ebaõnnestus.'
            );
        } finally {
            setUploadingFile(null);
        }
    };

    const handleDeleteStudy = async (studyId) => {
        if (window.confirm('Kas oled kindel, et soovid selle uuringu kustutada?')) {
            setIsLoading(true);
            try {
                const response = await axios.delete(
                    `https://helikon.technology:5000/admin/studies/${studyId}`,
                    { headers: { Authorization: `Bearer ${token}` } }
                );
                setResponseMessage(response.data.message);
                fetchAllStudies();
            } catch (error) {
                console.error('Uuringu kustutamine ebaõnnestus:', error);
                setResponseMessage(
                    error.response?.data?.message || 'Uuringu kustutamine ebaõnnestus.'
                );
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleDownloadResponseFile = async (studyId) => {
        try {
            const response = await axios.get(
                `https://helikon.technology:5000/studies/${studyId}/response`,
                {
                    headers: { Authorization: `Bearer ${token}` },
                    responseType: 'blob',
                }
            );

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `response_${studyId}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Vastusefaili allalaadimine ebaõnnestus:', error);
            setResponseMessage('Vastusefaili allalaadimine ebaõnnestus.');
        }
    };

    const filteredStudies = allStudies.filter(
        (study) =>
            (selectedStudyType === 'Kõik' || study.name === selectedStudyType) &&
            (study.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                (study.patientName && study.patientName.toLowerCase().includes(searchQuery.toLowerCase())))
    );

    const totalPages = Math.ceil(filteredStudies.length / itemsPerPage);
    const paginatedStudies = filteredStudies.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    return (
        <div className="container mt-4">
            <h2>Admin Paneel - Kõik Uuringud</h2>

            {responseMessage && (
                <div className="alert alert-info text-center">{responseMessage}</div>
            )}

            {isLoading && <div className="alert alert-warning text-center">Laadimine...</div>}

            <div className="filter-container mb-3">
                <select
                    className="form-select"
                    value={selectedStudyType}
                    onChange={(e) => setSelectedStudyType(e.target.value)}
                >
                    {studyTypes.map((type) => (
                        <option key={type} value={type}>
                            {type}
                        </option>
                    ))}
                </select>
                <input
                    type="text"
                    className="form-control mt-2"
                    placeholder="Otsi uuringu või patsiendi järgi"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            </div>

            <div className="study-list">
                {paginatedStudies.length > 0 ? (
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Uuring</th>
                                <th>Patsiendi nimi</th>
                                <th>Laadimise aeg</th>
                                <th>Tegevused</th>
                            </tr>
                        </thead>
                            <tbody>
                                {paginatedStudies.map((study) => (
                                    <tr key={study.id}>
                                        <td>{study.name}</td>
                                        <td>{study.patientName}</td>
                                        <td>{new Date(study.createdAt).toLocaleString()}</td>
                                        <td>
                                            {/* Faili valimise ja üleslaadimise sektsioon */}
                                            <div className="d-flex flex-wrap align-items-center mb-2">
                                                <div className="me-2">
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        onChange={(e) => {
                                                            if (e.target.files.length > 0) {
                                                                setResponseFile(e.target.files[0]);
                                                            } else {
                                                                setResponseFile(null);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <button
                                                    className="btn btn-sm btn-primary"
                                                    onClick={(e) => handleUploadResponseFile(e, study.id)}
                                                    disabled={uploadingFile === study.id || !responseFile}
                                                >
                                                    {uploadingFile === study.id ? 'Laadin üles...' : 'Lisa vastus'}
                                                </button>
                                            </div>

                                            {/* Faili allalaadimise nupp - nähtav ainult siis, kui fail on olemas */}
                                            {study.responseFile && (
                                                <button
                                                    className="btn btn-sm btn-success me-2"
                                                    onClick={() => handleDownloadResponseFile(study.id)}
                                                >
                                                    Laadi alla
                                                </button>
                                            )}

                                            {/* Uuringu kustutamise nupp */}
                                            <button
                                                className="btn btn-sm btn-danger"
                                                onClick={() => handleDeleteStudy(study.id)}
                                            >
                                                Kustuta
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>

                    </table>
                ) : (
                    <p className="text-center">Ühtegi uuringut pole leitud.</p>
                )}
            </div>

            {totalPages > 1 && (
                <nav className="pagination-container mt-3">
                    <ul className="pagination justify-content-center">
                        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                            <button
                                className="page-link"
                                onClick={() => handlePageChange(currentPage - 1)}
                            >
                                Eelmine
                            </button>
                        </li>
                        {Array.from({ length: totalPages }, (_, i) => (
                            <li
                                key={i + 1}
                                className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}
                            >
                                <button
                                    className="page-link"
                                    onClick={() => handlePageChange(i + 1)}
                                >
                                    {i + 1}
                                </button>
                            </li>
                        ))}
                        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                            <button
                                className="page-link"
                                onClick={() => handlePageChange(currentPage + 1)}
                            >
                                Järgmine
                            </button>
                        </li>
                    </ul>
                </nav>
            )}
        </div>
    );
}

export default AdminPanel;
