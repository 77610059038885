import React, { useState } from 'react';
import axios from 'axios';
import './CSS/UploadStudy.css'; 

function UploadStudy({ token }) {
    const [files, setFiles] = useState([]);
    const [studyType, setStudyType] = useState('');
    const [patientName, setPatientName] = useState('');
    const [patientId, setPatientId] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [uploadingFiles, setUploadingFiles] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    const studyTypes = [
        'Vererõhu holter',
        'Rütmi holter',
        'MRT uuring',
        'Ultraheli uuring',
        'Ehhokardiogramm',
    ];

    const handleFilesChange = (e) => setFiles([...e.target.files]);

    const handleUpload = async () => {
        if (!studyType || !patientName || !patientId) {
            setResponseMessage('Kõik väljad on kohustuslikud!');
            return;
        }

        if (files.length === 0) {
            setResponseMessage('Palun lisa vähemalt üks fail.');
            return;
        }

        const formData = new FormData();
        files.forEach((file) => formData.append('files', file));
        formData.append(
            'patientData',
            JSON.stringify({ studyType, patientName, patientId })
        );

        console.log('Saadetavad patientData andmed:', { studyType, patientName, patientId });

        try {
            setUploadingFiles(true);
            setUploadProgress(0);

            const response = await axios.post('https://helikon.technology:5000/studies', formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    setUploadProgress(progress);
                },
            });
            setResponseMessage(response.data.message);
            setFiles([]);
            setStudyType('');
            setPatientName('');
            setPatientId('');
        } catch (error) {
            console.error('Uuringu üleslaadimine ebaõnnestus:', error);
            setResponseMessage('Uuringu üleslaadimine ebaõnnestus.');
        } finally {
            setUploadingFiles(false);
            setTimeout(() => setUploadProgress(0), 1000); // Taasta algne olek
        }
    };

    return (
        <div className="upload-form">
            <h3>Uuringu üleslaadimine</h3>
            {responseMessage && (
                <div
                    className={`alert text-center ${
                        responseMessage.includes('ebaõnnestus') ? 'alert-danger' : 'alert-success'
                    }`}
                >
                    {responseMessage}
                </div>
            )}
            <div className="mb-3">
                <label htmlFor="studyType" className="form-label">
                    Uuringu tüüp
                </label>
                <select
                    id="studyType"
                    className="form-control"
                    value={studyType}
                    onChange={(e) => setStudyType(e.target.value)}
                >
                    <option value="">Vali uuringu tüüp</option>
                    {studyTypes.map((type) => (
                        <option key={type} value={type}>
                            {type}
                        </option>
                    ))}
                </select>
            </div>
            <div className="mb-3">
                <label htmlFor="patientName" className="form-label">
                    Patsiendi nimi
                </label>
                <input
                    id="patientName"
                    type="text"
                    className="form-control"
                    value={patientName}
                    onChange={(e) => setPatientName(e.target.value)}
                />
            </div>
            <div className="mb-3">
                <label htmlFor="patientId" className="form-label">
                    Patsiendi isikukood
                </label>
                <input
                    id="patientId"
                    type="text"
                    className="form-control"
                    value={patientId}
                    onChange={(e) => setPatientId(e.target.value)}
                />
            </div>
            <div className="mb-3">
                <label htmlFor="files" className="form-label">
                    Failid
                </label>
                <input
                    id="files"
                    type="file"
                    className="form-control"
                    multiple
                    onChange={handleFilesChange}
                />
            </div>
            <button
                className="btn btn-primary"
                onClick={handleUpload}
                disabled={uploadingFiles}
            >
                {uploadingFiles ? 'Laadin üles...' : 'Laadi üles'}
            </button>

            {/* Laadimise edenemise animatsioon */}
            {uploadingFiles && (
                <div className="progress-container">
                    <div
                        className="progress-circle"
                        style={{ background: `conic-gradient(#007bff ${uploadProgress}%, #e0e0e0 ${uploadProgress}%)` }}
                    >
                        <span className="progress-text">{uploadProgress}%</span>
                    </div>
                </div>
            )}
        </div>
    );
}

export default UploadStudy;
