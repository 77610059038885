import React, { useState } from 'react';
import axios from 'axios';
import AddressAutocomplete from '../Functions/AddressAutocomplete';

function UserRegistration({ token, fetchUsers }) {
    const [newUser, setNewUser] = useState({
        username: '',
        email: '',
        password: '',
        role: 'user',
        phone: '',
        fullName: '',
        address: '',
        institution: '',
        doctorId: '',
        features: [],
    });
    const [responseMessage, setResponseMessage] = useState('');

    const handleAddUser = async (e) => {
        e.preventDefault();
        if (
            !newUser.username ||
            !newUser.email ||
            !newUser.password ||
            !newUser.phone ||
            !newUser.fullName ||
            !newUser.address ||
            !newUser.institution ||
            !newUser.doctorId
        ) {
            setResponseMessage('Kõik väljad on kohustuslikud.');
            return;
        }

        try {
            const response = await axios.post(
                'https://helikon.technology:5000/admin/users',
                {
                    ...newUser,
                    features: newUser.features.join(','), // Muudame features stringiks
                },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            setResponseMessage('Kasutaja edukalt lisatud!');
            setNewUser({
                username: '',
                email: '',
                password: '',
                role: 'user',
                phone: '',
                fullName: '',
                address: '',
                institution: '',
                doctorId: '',
                features: [],
            });
            if (fetchUsers) {
                fetchUsers(); // Laadi uuesti kasutajate nimekiri
            }
        } catch (error) {
            console.error('Kasutaja lisamine ebaõnnestus:', error);
            setResponseMessage(
                error.response?.data?.message || 'Kasutaja lisamine ebaõnnestus.'
            );
        }
    };

    const handleFeatureToggle = (feature) => {
        setNewUser((prevUser) => ({
            ...prevUser,
            features: prevUser.features.includes(feature)
                ? prevUser.features.filter((f) => f !== feature) // Eemalda, kui juba olemas
                : [...prevUser.features, feature], // Lisa, kui pole olemas
        }));
    };

    return (
        <div className="container mt-4">
            <h2>Registreeri uus kasutaja</h2>
            {responseMessage && (
                <div
                    className={`alert ${
                        responseMessage.includes('ebaõnnestus')
                            ? 'alert-danger'
                            : 'alert-success'
                    } text-center`}
                >
                    {responseMessage}
                </div>
            )}
            <form onSubmit={handleAddUser}>
                <div className="mb-3">
                    <label className="form-label">Kasutajanimi</label>
                    <input
                        type="text"
                        className="form-control"
                        value={newUser.username}
                        onChange={(e) =>
                            setNewUser({ ...newUser, username: e.target.value })
                        }
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">E-post</label>
                    <input
                        type="email"
                        className="form-control"
                        value={newUser.email}
                        onChange={(e) =>
                            setNewUser({ ...newUser, email: e.target.value })
                        }
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Parool</label>
                    <input
                        type="password"
                        className="form-control"
                        value={newUser.password}
                        onChange={(e) =>
                            setNewUser({ ...newUser, password: e.target.value })
                        }
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Telefon</label>
                    <input
                        type="text"
                        className="form-control"
                        value={newUser.phone}
                        onChange={(e) =>
                            setNewUser({ ...newUser, phone: e.target.value })
                        }
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Täisnimi</label>
                    <input
                        type="text"
                        className="form-control"
                        value={newUser.fullName}
                        onChange={(e) =>
                            setNewUser({ ...newUser, fullName: e.target.value })
                        }
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Aadress</label>
                    {/* Kasutame AddressAutocomplete komponenti */}
                    <AddressAutocomplete
                        onAddressSelect={(address) =>
                            setNewUser({ ...newUser, address })
                        }
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Asutuse nimi</label>
                    <input
                        type="text"
                        className="form-control"
                        value={newUser.institution}
                        onChange={(e) =>
                            setNewUser({ ...newUser, institution: e.target.value })
                        }
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Arsti ID</label>
                    <input
                        type="text"
                        className="form-control"
                        value={newUser.doctorId}
                        onChange={(e) =>
                            setNewUser({ ...newUser, doctorId: e.target.value })
                        }
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Roll</label>
                    <select
                        className="form-select"
                        value={newUser.role}
                        onChange={(e) =>
                            setNewUser({ ...newUser, role: e.target.value })
                        }
                    >
                        <option value="user">Tavakasutaja</option>
                        <option value="admin">Admin</option>
                    </select>
                </div>
                <div className="mb-3">
                    <label className="form-label">Funktsioonid (Features)</label>
                    <div className="form-check">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="featureAnalysis"
                            checked={newUser.features.includes('Uuringu analüüs')}
                            onChange={() => handleFeatureToggle('Uuringu analüüs')}
                        />
                        <label className="form-check-label" htmlFor="featureAnalysis">
                            EKG Holter analüüs
                        </label>
                    </div>
                    <div className="form-check">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="featurePildipank"
                            checked={newUser.features.includes('Pildipanka saatmine')}
                            onChange={() => handleFeatureToggle('Pildipanka saatmine')}
                        />
                        <label className="form-check-label" htmlFor="featurePildipank">
                            Pildipanka saatmine
                        </label>
                    </div>
                </div>
                <button type="submit" className="btn btn-success">
                    Registreeri kasutaja
                </button>
            </form>
        </div>
    );
}

export default UserRegistration;
