import React, { useState } from 'react';
import axios from 'axios';

function Login({ onLogin }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false); // Laadimise indikaator

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(''); // Tühjenda eelmine veateade
        setIsLoading(true); // Alusta laadimist
        try {
            const response = await axios.post('https://helikon.technology:5000/login', {
                username,
                password,
            });

            const token = response.data.token;
            localStorage.setItem('token', token); // Salvestame tokeni LocalStorage
            onLogin(token); // Edastame tokeni vanemkomponendile (App.js)
            window.location.href = '/'; // Suuname pärast sisselogimist
        } catch (error) {
            console.error('Sisselogimine ebaõnnestus:', error);
            setError(
                error.response?.data?.message ||
                'Sisselogimine ebaõnnestus. Kontrolli kasutajanime ja parooli.'
            );
        } finally {
            setIsLoading(false); // Lõpeta laadimine
        }
    };

    return (
        <div className="container mt-5">
            {/* Lisa logo */}
            <div className="text-center mb-4">
                <img
                    src={`${process.env.PUBLIC_URL}/PildiTugi.png`}
                    alt="PildiTugi logo"
                    style={{ width: '300px', height: '250px' }}
                />
            </div>
            <h2 className="text-center mb-4">Logi sisse</h2>
            {error && <div className="alert alert-danger">{error}</div>}
            <form onSubmit={handleSubmit} className="mx-auto" style={{ maxWidth: '400px' }}>
                <div className="mb-3">
                    <label className="form-label">Kasutajanimi</label>
                    <input
                        type="text"
                        className="form-control"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                        placeholder="Sisesta oma kasutajanimi"
                        disabled={isLoading} // Keela sisend laadimise ajal
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Parool</label>
                    <input
                        type="password"
                        className="form-control"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        placeholder="Sisesta oma parool"
                        disabled={isLoading} // Keela sisend laadimise ajal
                    />
                </div>
                <button
                    type="submit"
                    className="btn btn-primary w-100"
                    disabled={isLoading} // Keela nupp laadimise ajal
                >
                    {isLoading ? 'Laadin...' : 'Logi sisse'}
                </button>
            </form>
        </div>
    );
}

export default Login;
