import React, { useState } from 'react';
import axios from 'axios';

function UserEdit({ token, user, onUpdate, onCancel }) {
    const [editedUser, setEditedUser] = useState({
        ...user,
        features: user.features ? user.features.split(',') : [],
    });
    const [responseMessage, setResponseMessage] = useState('');

    const handleSaveUser = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.put(
                `https://helikon.technology:5000/admin/users/${editedUser.id}`,
                {
                    ...editedUser,
                    features: editedUser.features.join(','), // Funktsioonid stringiks
                },
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            setResponseMessage('Kasutaja andmed on edukalt uuendatud!');
            if (onUpdate) {
                onUpdate(); // Värskenda kasutajate nimekiri
            }
        } catch (error) {
            console.error('Kasutaja uuendamine ebaõnnestus:', error);
            setResponseMessage(
                error.response?.data?.message || 'Kasutaja uuendamine ebaõnnestus.'
            );
        }
    };

    const handleFeatureToggle = (feature) => {
        setEditedUser((prevUser) => ({
            ...prevUser,
            features: prevUser.features.includes(feature)
                ? prevUser.features.filter((f) => f !== feature)
                : [...prevUser.features, feature],
        }));
    };

    return (
        <div className="container mt-4">
            <h2>Muuda kasutajat: {user.username}</h2>
            {responseMessage && (
                <div
                    className={`alert ${
                        responseMessage.includes('ebaõnnestus') ? 'alert-danger' : 'alert-success'
                    } text-center`}
                >
                    {responseMessage}
                </div>
            )}
            <form onSubmit={handleSaveUser}>
                <div className="mb-3">
                    <label className="form-label">Kasutajanimi</label>
                    <input
                        type="text"
                        className="form-control"
                        value={editedUser.username}
                        onChange={(e) =>
                            setEditedUser({ ...editedUser, username: e.target.value })
                        }
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">E-post</label>
                    <input
                        type="email"
                        className="form-control"
                        value={editedUser.email}
                        onChange={(e) =>
                            setEditedUser({ ...editedUser, email: e.target.value })
                        }
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Telefon</label>
                    <input
                        type="text"
                        className="form-control"
                        value={editedUser.phone}
                        onChange={(e) =>
                            setEditedUser({ ...editedUser, phone: e.target.value })
                        }
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Täisnimi</label>
                    <input
                        type="text"
                        className="form-control"
                        value={editedUser.fullName}
                        onChange={(e) =>
                            setEditedUser({ ...editedUser, fullName: e.target.value })
                        }
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Aadress</label>
                    <input
                        type="text"
                        className="form-control"
                        value={editedUser.address}
                        onChange={(e) =>
                            setEditedUser({ ...editedUser, address: e.target.value })
                        }
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Asutuse nimi</label>
                    <input
                        type="text"
                        className="form-control"
                        value={editedUser.institution}
                        onChange={(e) =>
                            setEditedUser({ ...editedUser, institution: e.target.value })
                        }
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Arsti ID</label>
                    <input
                        type="text"
                        className="form-control"
                        value={editedUser.doctorId}
                        onChange={(e) =>
                            setEditedUser({ ...editedUser, doctorId: e.target.value })
                        }
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Roll</label>
                    <select
                        className="form-select"
                        value={editedUser.role}
                        onChange={(e) =>
                            setEditedUser({ ...editedUser, role: e.target.value })
                        }
                    >
                        <option value="user">Tavakasutaja</option>
                        <option value="admin">Admin</option>
                    </select>
                </div>
                <div className="mb-3">
                    <label className="form-label">Funktsioonid (Features)</label>
                    <div className="form-check">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="featureAnalysis"
                            checked={editedUser.features.includes('Uuringu analüüs')}
                            onChange={() => handleFeatureToggle('Uuringu analüüs')}
                        />
                        <label className="form-check-label" htmlFor="featureAnalysis">
                            EKG Holter analüüs
                        </label>
                    </div>
                    <div className="form-check">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="featurePildipank"
                            checked={editedUser.features.includes('Pildipanka saatmine')}
                            onChange={() => handleFeatureToggle('Pildipanka saatmine')}
                        />
                        <label className="form-check-label" htmlFor="featurePildipank">
                            Pildipanka saatmine
                        </label>
                    </div>
                </div>
                <button type="submit" className="btn btn-success">
                    Salvesta
                </button>
                <button
                    type="button"
                    className="btn btn-secondary ms-2"
                    onClick={onCancel}
                >
                    Tühista
                </button>
            </form>
        </div>
    );
}

export default UserEdit;
