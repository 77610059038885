import React, { useState, useEffect } from 'react';
import axios from 'axios';

function StudiesCount({ token }) {
    const [userStats, setUserStats] = useState([]);
    const [allStats, setAllStats] = useState([]);
    const [error, setError] = useState('');
    const [isAdmin, setIsAdmin] = useState(false); // Assume a function or state that checks if the user is an admin

    // Fetch the statistics for the logged-in user
    const fetchUserStats = async () => {
        try {
            const response = await axios.get('https://helikon.technology:5000/studies-count/user-stats', {
                headers: { Authorization: `Bearer ${token}` },
            });
            setUserStats(response.data);
        } catch (err) {
            setError('Failed to fetch user statistics');
            console.error(err);
        }
    };

    // Fetch the statistics for all users (admin only)
    const fetchAllStats = async () => {
        if (!isAdmin) return;
        try {
            const response = await axios.get('https://helikon.technology:5000/studies-count/stats', {
                headers: { Authorization: `Bearer ${token}` },
            });
            setAllStats(response.data);
        } catch (err) {
            setError('Failed to fetch all users statistics');
            console.error(err);
        }
    };

    useEffect(() => {
        fetchUserStats();
        if (isAdmin) {
            fetchAllStats();
        }
    }, [token, isAdmin]);

    return (
        <div>
            <h3>Teostatud uuringute statistika:</h3>
            <ul>
                {userStats.map((stat, index) => (
                    <li key={index}>{stat.month} - Kõik üleslaadimised: {stat.totalUploads}</li>
                ))}
            </ul>
            {isAdmin && (
                <>
                    <h3>Kõigi kasutajate kuupõhine uuringute statistika (Admini vaade):</h3>
                    <ul>
                        {allStats.map((stat, index) => (
                            <li key={index}>
                                Kuupõhine: {stat.month} - Kasutaja ID: {stat.userId} - Teostatud uuringud: {stat.totalUploads}
                            </li>
                        ))}
                    </ul>
                </>
            )}
            {error && <div className="alert alert-danger">{error}</div>}
        </div>
    );
}

export default StudiesCount;
