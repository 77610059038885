import React, { useEffect, useState } from 'react';
import axios from 'axios';

function StudyResponses({ token }) {
    const [studies, setStudies] = useState([]);
    const [responseMessage, setResponseMessage] = useState('');
    const [selectedResponse, setSelectedResponse] = useState(null); // Uuringu vastuse kuvamiseks
    const [loadingResponse, setLoadingResponse] = useState(null); // Näitab vastuse allalaadimise olekut

    // Uuringute laadimine
    useEffect(() => {
        const fetchStudies = async () => {
            try {
                const response = await axios.get('https://helikon.technology:5000/studies', {
                    headers: { Authorization: `Bearer ${token}` },
                });
                const updatedStudies = response.data.map((study) => ({
                    ...study,
                    responseFileExists: !!study.responseFile, // Kontrollib, kas responseFile on saadaval
                }));
                setStudies(updatedStudies);
            } catch (error) {
                console.error('Uuringute laadimine ebaõnnestus:', error);
                setResponseMessage('Uuringute laadimine ebaõnnestus. Palun proovige hiljem uuesti.');
            }
        };

        fetchStudies();
    }, [token]);

    // Vastuse allalaadimine
    const handleDownloadResponseFile = async (studyId) => {
        setLoadingResponse(studyId); // Määrab laadimise oleku
        try {
            const response = await axios.get(
                `https://helikon.technology:5000/studies/${studyId}/response`,
                {
                    headers: { Authorization: `Bearer ${token}` },
                    responseType: 'blob',
                }
            );

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `response${studyId}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Vastusefaili allalaadimine ebaõnnestus:', error);
            setResponseMessage('Vastusefaili allalaadimine ebaõnnestus. Faili ei leitud või tekkis viga.');
        } finally {
            setLoadingResponse(null); // Tühistab laadimise oleku
        }
    };

    // Vastuse kuvamine
    const handleViewResponse = async (studyId) => {
        setLoadingResponse(studyId); // Kuvab laadimise olekut
        try {
            const response = await axios.get(
                `https://helikon.technology:5000/studies/${studyId}/response`,
                {
                    headers: { Authorization: `Bearer ${token}` },
                    responseType: 'blob',
                }
            );

            const url = window.URL.createObjectURL(new Blob([response.data]));
            setSelectedResponse(url); // Kuvab vastuse
        } catch (error) {
            console.error('Vastusefaili kuvamine ebaõnnestus:', error);
            setResponseMessage('Vastusefaili kuvamine ebaõnnestus. Faili ei leitud või tekkis viga.');
        } finally {
            setLoadingResponse(null); // Tühistab laadimise oleku
        }
    };

    const formatDateTime = (datetime) => {
        const date = new Date(datetime);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    };

    return (
        <div>
            <h3>Üleslaaditud uuringud</h3>
            {responseMessage && <p className="text-danger">{responseMessage}</p>}
            {studies.length > 0 ? (
                <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                    <ul className="list-group">
                        {studies.map((study) => (
                            <li
                                key={study.id}
                                className="list-group-item d-flex justify-content-between align-items-center"
                            >
                                <div>
                                    <span>
                                        <strong>Uuring:</strong> {study.name} |{' '}
                                        <strong>Patsiendi nimi:</strong> {study.patientName}
                                    </span>
                                    <br />
                                    <small>
                                        <strong>Laadimise aeg:</strong> {formatDateTime(study.createdAt)}
                                    </small>
                                </div>
                                <div>
                                    {study.responseFileExists ? (
                                        <div>
                                            <i
                                                className="fas fa-file-pdf pdf-icon"
                                                title="Laadi alla vastus"
                                                onClick={() => handleDownloadResponseFile(study.id)}
                                                style={{
                                                    cursor: 'pointer',
                                                    fontSize: '24px',
                                                    color: 'red',
                                                    marginRight: '10px',
                                                }}
                                            ></i>
                                            <button
                                                className="btn btn-info btn-sm"
                                                onClick={() => handleViewResponse(study.id)}
                                            >
                                                Vaata vastust
                                            </button>
                                        </div>
                                    ) : (
                                        <span className="text-muted">Analüüsimisel</span>
                                    )}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            ) : (
                <p>Uuringuid ei leitud.</p>
            )}

            {/* Kuvatud vastus (nt PDF või tekst) */}
            {selectedResponse && (
                <div className="mt-4">
                    <h4>Uuringu vastus</h4>
                    <iframe
                        src={selectedResponse}
                        title="Uuringu vastus"
                        style={{ width: '100%', height: '500px' }}
                    ></iframe>
                    <button
                        className="btn btn-secondary mt-2"
                        onClick={() => setSelectedResponse(null)}
                    >
                        Sule vastus
                    </button>
                </div>
            )}
        </div>
    );
}

export default StudyResponses;
