import React, { useEffect, useState } from 'react';
import axios from 'axios';
import AdminPanel from './AdminPanel';
import UploadHistory from './UploadHistory';
import UploadStudy from './UploadStudy';
import StudyResponses from './StudyResponses';
import AdminDashboard from './AdminDashboard';
import UserRegistration from './Admin/UserRegistration';
import StudiesCount from './Functions/StudiesCount';

function Dashboard({ token, onLogout }) {
    const [responseMessage, setResponseMessage] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [userInfo, setUserInfo] = useState(null);
    const [showWelcomeMessage, setShowWelcomeMessage] = useState(true);

    // Muuda esimeseks leheks `uploads` ja loe `localStorage` väärtus
    const [currentTab, setCurrentTab] = useState(
        localStorage.getItem('currentTab') || 'uploads'
    );

    // Kui vahetatakse aktiivset lehte, salvesta uus vaade `localStorage`-i
    const handleTabChange = (tab) => {
        setCurrentTab(tab);
        localStorage.setItem('currentTab', tab); // Salvesta aktiivne leht
    };

    // Lae kasutaja andmed serverist
    const fetchUserInfo = async () => {
        try {
            const response = await axios.get('https://helikon.technology:5000/validate-token', {
                headers: { Authorization: `Bearer ${token}` },
            });
            setUserInfo(response.data.user);
        } catch (error) {
            console.error('Kasutaja andmete laadimine ebaõnnestus:', error);
            setResponseMessage('Sisselogimise seanss on aegunud. Palun logige uuesti sisse.');
            onLogout();
        } finally {
            setIsLoading(false);
        }
    };

    // Peida tervitusteade 10 sekundi pärast
    useEffect(() => {
        const timer = setTimeout(() => setShowWelcomeMessage(false), 10000);
        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        fetchUserInfo();
    }, [token]);

    const handleLogout = () => {
        {
            onLogout();
        }
    };

    return (
        <div className="container mt-4">
            {/* Navbar */}
            <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex justify-content-center" style={{ marginTop: '20px' }}>
                    <img
                        src={`${process.env.PUBLIC_URL}/PildiTugi.png`}
                        alt="PildiTugi logo"
                        style={{ maxHeight: '230px' }}
                    />
                </div>
                <nav className="navbar navbar-expand-lg navbar-light bg-light mb-4">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <button
                                className={`btn btn-link nav-link ${currentTab === 'uploads' ? 'active' : ''}`}
                                onClick={() => handleTabChange('uploads')}
                            >
                                Uuringud
                            </button>
                        </li>
                        <li className="nav-item">
                            <button
                                className={`btn btn-link nav-link ${currentTab === 'uploadStudy' ? 'active' : ''}`}
                                onClick={() => handleTabChange('uploadStudy')}
                            >
                                Üleslaadimine
                            </button>
                        </li>
                        {userInfo?.role === 'admin' && (
                        <li className="nav-item">
                            <button
                                className={`btn btn-link nav-link ${currentTab === 'studiesCount' ? 'active' : ''}`}
                                onClick={() => handleTabChange('studiesCount')}
                            >
                                Statistika
                            
                            </button>
                        </li>
                        )}
                        {userInfo?.role === 'admin' && (
                            <li className="nav-item">
                                <button
                                    className={`btn btn-link nav-link ${currentTab === 'adminDashboard' ? 'active' : ''}`}
                                    onClick={() => handleTabChange('adminDashboard')}
                                >
                                    Kasutajad
                                </button>
                            </li>
                        )}
                        {userInfo?.role === 'admin' && (
                            <li className="nav-item">
                                <button
                                    className={`btn btn-link nav-link ${currentTab === 'admin' ? 'active' : ''}`}
                                    onClick={() => handleTabChange('admin')}
                                >
                                    Lisa vastus
                                </button>
                            </li>
                        )}
                        {userInfo?.role === 'admin' && (
                            <li className="nav-item">
                                <button
                                    className={`btn btn-link nav-link ${
                                        currentTab === 'userRegistration' ? 'active' : ''
                                    }`}
                                    onClick={() => handleTabChange('userRegistration')}
                                >
                                    Kasutaja registreerimine
                                </button>
                            </li>
                        )}
                    </ul>
                    <button className="btn btn-danger ms-auto" onClick={handleLogout}>
                        Logi välja
                    </button>
                </nav>
            </div>

            {/* Tervitusteade */}
            {userInfo && showWelcomeMessage && (
                <div className="alert alert-success text-center">
                    Tere tulemast, {userInfo.username}!
                </div>
            )}

            {/* Teavitused ja laadimisindikaator */}
            {responseMessage && (
                <div className="alert alert-info text-center">{responseMessage}</div>
            )}
            {isLoading && <div className="alert alert-warning text-center">Laadimine...</div>}

            {/* Peamine sisu */}
            {!isLoading && (
                <>
                    {currentTab === 'uploads' && <UploadHistory token={token} />}
                    {currentTab === 'uploadStudy' && <UploadStudy token={token} />}
                    {currentTab === 'studyResponses' && <StudyResponses token={token} />}
                    {currentTab === 'admin' && userInfo?.role === 'admin' && <AdminPanel token={token} />}
                    {currentTab === 'adminDashboard' && userInfo?.role === 'admin' && <AdminDashboard token={token} />}
                    {currentTab === 'userRegistration' && userInfo?.role === 'admin' && (<UserRegistration token={token} />)}
                    {currentTab === 'studiesCount' && <StudiesCount token={token} />}

                </>
            )}
        </div>
    );
}

export default Dashboard;
