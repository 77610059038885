import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './Login';
import Dashboard from './Dashboard';
import AdminDashboard from './AdminDashboard';
import { validateToken } from './api'; // Kasutame keskset API moodulit
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [token, setToken] = useState(null);
    const [userRole, setUserRole] = useState(null); // Rolli jälgimiseks
    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null); // Veateade kasutajale
    const [responseMessage, setResponseMessage] = useState('');
   
    // Sisselogimise haldamine
    const handleLogin = (token) => {
        setToken(token);
        localStorage.setItem('token', token); // Salvesta token
        setIsLoggedIn(true);
        setIsLoading(false);
    };

    // Väljalogimise haldamine
    const handleLogout = () => {
        if (window.confirm('Kas oled kindel, et soovid välja logida?')) {
            setToken(null);
            localStorage.removeItem('token');
            setIsLoggedIn(false);
            setUserRole(null);
        }
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (isLoading) {
                setResponseMessage('Toiming võtab liiga kaua aega. Kontrolli oma võrku või proovi uuesti.');
                setIsLoading(false);
            }
        }, 15000); // 15 sekundit

        return () => clearTimeout(timeout); // Tühista timeout
    }, [isLoading]);

    

    // Tokeni valideerimine ja kasutajaandmete laadimine
    useEffect(() => {
        const savedToken = localStorage.getItem('token');
        if (savedToken) {
            setIsLoading(true); // Alusta valideerimist
            validateToken(savedToken)
                .then((response) => {
                    setToken(savedToken);
                    setUserRole(response.data.user.role); // Seadista kasutaja roll
                    setIsLoggedIn(true);
                })
                .catch((error) => {
                    console.error('Valideerimine ebaõnnestus:', error.response?.data);
                    setErrorMessage('Valideerimine ebaõnnestus. Palun logi uuesti sisse.');
                    handleLogout();
                })
                .finally(() => {
                    setIsLoading(false); // Valideerimine lõppenud
                });
        } else {
            setIsLoading(false); // Kui tokenit pole, lõpetame kohe
        }
    }, []);

    if (isLoading) {
        return <div className="container text-center mt-5">Laadimine...</div>;
    }

    return (
        <Router>
            <div className="container mt-4">
                {errorMessage && (
                    <div className="alert alert-danger text-center">{errorMessage}</div>
                )}
            </div>
            <Routes>
                {!isLoggedIn ? (
                    <Route path="*" element={<Login onLogin={handleLogin} />} />
                ) : (
                    <>
                        <Route
                            path="/"
                            element={<Dashboard token={token} onLogout={handleLogout} />}
                        />
                        {userRole === 'admin' && (
                            <Route
                                path="/AdminDashboard"
                                element={<AdminDashboard token={token} onLogout={handleLogout} />}
                            />
                        )}
                        <Route path="*" element={<Navigate to="/" replace />} />
                    </>
                )}
            </Routes>
        </Router>
    );
}

export default App;
