import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './CSS/AdminDashboard.css'; // CSS faili import
import UserEdit from './Admin/UserEdit';

function AdminDashboard({ token }) {
    const [users, setUsers] = useState([]); // Kasutajate nimekiri
    const [editingUser, setEditingUser] = useState(null); // Muudetav kasutaja
    const [responseMessage, setResponseMessage] = useState(''); // Serveri vastus
    const [isLoading, setIsLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    // Lae kasutajate nimekiri
    const fetchUsers = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get('https://helikon.technology:5000/admin/users', {
                headers: { Authorization: `Bearer ${token}` },
            });
    
            const sortedUsers = response.data.sort((a, b) => {
                const institutionA = a.institution || ''; // Vaikimisi tühi string
                const institutionB = b.institution || ''; // Vaikimisi tühi string
                return institutionA.localeCompare(institutionB);
            });
    
            setUsers(sortedUsers);
        } catch (error) {
            console.error('Kasutajate laadimine ebaõnnestus:', error);
            setResponseMessage(
                error.response?.data?.message || 'Kasutajate laadimine ebaõnnestus.'
            );
        } finally {
            setIsLoading(false);
        }
    };
    

    useEffect(() => {
        fetchUsers();
    }, [token]);

    // Filtreeritud ja otsitud kasutajad
    const filteredUsers = users.filter((user) => {
        const username = user.username || ''; // Kui username on null, asenda tühja stringiga
        const email = user.email || ''; // Kui email on null, asenda tühja stringiga
        const institution = user.institution || ''; // Kui institution on null, asenda tühja stringiga
        return (
            username.toLowerCase().includes(searchQuery.toLowerCase()) ||
            email.toLowerCase().includes(searchQuery.toLowerCase()) ||
            institution.toLowerCase().includes(searchQuery.toLowerCase())
        );
    });

    // Lehekülgede jagamine
    const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);
    const paginatedUsers = filteredUsers.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    // Kasutaja kustutamine
    const handleDeleteUser = async (userId) => {
        const confirmDelete = window.confirm('Kas soovid selle kasutaja kustutada?');
        if (!confirmDelete) return;

        try {
            const response = await axios.delete(
                `https://helikon.technology:5000/admin/users/${userId}`,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
            setResponseMessage(response.data.message);
            fetchUsers();
        } catch (error) {
            console.error('Kasutaja kustutamine ebaõnnestus:', error);
            setResponseMessage(
                error.response?.data?.message || 'Kasutaja kustutamine ebaõnnestus.'
            );
        }
    };

    return (
        <div className="container mt-4">
            <div className="header d-flex justify-content-between align-items-center">
                <h1>Kasutajad</h1>
            </div>

            {responseMessage && (
                <div
                    className={`alert ${
                        responseMessage.includes('ebaõnnestus') ? 'alert-danger' : 'alert-success'
                    } text-center`}
                >
                    {responseMessage}
                </div>
            )}

            {isLoading ? (
                <div className="alert alert-warning text-center">Laadimine...</div>
            ) : (
                <>
                    {/* Otsinguväli */}
                    <input
                        type="text"
                        className="form-control mb-3"
                        placeholder="Otsi kasutajanime, e-posti või asutuse nime järgi"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />

                    {/* Kasutajate nimekiri */}
                    <ul className="list-group mb-4">
                        {paginatedUsers.map((user) => (
                            <li
                                key={user.id}
                                className="list-group-item d-flex justify-content-between align-items-center"
                            >
                                <div>
                                    <strong>Kasutajanimi:</strong> {user.username} <br />
                                    <strong>Email:</strong> {user.email} <br />
                                    <strong>Telefon:</strong> {user.phone} <br />
                                    <strong>Täisnimi:</strong> {user.fullName} <br />
                                    <strong>Aadress:</strong> {user.address} <br />
                                    <strong>Asutus:</strong> {user.institution} <br />
                                    <strong>Arsti ID:</strong> {user.doctorId} <br />
                                    <strong>Roll:</strong> {user.role} <br />
                                    <strong>Funktsioonid:</strong>{' '}
                                    {user.features ? user.features.split(',').join(', ') : 'Pole määratud'}
                                </div>
                                <div>
                                    <button
                                        className="btn btn-primary btn-sm me-2"
                                        onClick={() => setEditingUser(user)}
                                    >
                                        Muuda
                                    </button>
                                    {user.role !== 'admin' && (
                                        <button
                                            className="btn btn-danger btn-sm"
                                            onClick={() => handleDeleteUser(user.id)}
                                        >
                                            Kustuta
                                        </button>
                                    )}
                                </div>
                            </li>
                        ))}
                    </ul>
                    {editingUser && (
                            <UserEdit
                                token={token}
                                user={editingUser}
                                onUpdate={fetchUsers}
                                onCancel={() => setEditingUser(null)}
                            />
                        )}
                    {/* Lehekülgede jagamine */}
                    {totalPages > 1 && (
                        <nav className="pagination-container mt-3">
                            <ul className="pagination justify-content-center">
                                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                    <button
                                        className="page-link"
                                        onClick={() => handlePageChange(currentPage - 1)}
                                    >
                                        Eelmine
                                    </button>
                                </li>
                                {Array.from({ length: totalPages }, (_, i) => (
                                    <li
                                        key={i + 1}
                                        className={`page-item ${
                                            currentPage === i + 1 ? 'active' : ''
                                        }`}
                                    >
                                        <button
                                            className="page-link"
                                            onClick={() => handlePageChange(i + 1)}
                                        >
                                            {i + 1}
                                        </button>
                                    </li>
                                ))}
                                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                    <button
                                        className="page-link"
                                        onClick={() => handlePageChange(currentPage + 1)}
                                    >
                                        Järgmine
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    )}
                </>
            )}
        </div>
    );
}

export default AdminDashboard;
