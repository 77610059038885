import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './CSS/UploadHistory.css'; // CSS faili importimine

function UploadHistory({ token }) {
    const [history, setHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedStudyType, setSelectedStudyType] = useState('Kõik'); // Uuringutüübi filter
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const studyTypes = ['Kõik', 'Vererõhu holter', 'Rütmi holter', 'MRT uuring', 'Ultraheli uuring', 'Ehhokardiogramm'];

    useEffect(() => {
        const fetchHistory = async () => {
            setLoading(true);
            setError(null);
            try {
                const response = await axios.get('https://helikon.technology:5000/studies', {
                    headers: { Authorization: `Bearer ${token}` },
                });

                // Sorteeri uuringud kuupäeva järgi (uuemad eespool)
                const sortedHistory = response.data.sort(
                    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                );
                setHistory(sortedHistory);
            } catch (error) {
                console.error('Ajaloo laadimine ebaõnnestus:', error);
                setError('Uuringute ajaloo laadimine ebaõnnestus. Palun proovige hiljem uuesti.');
            } finally {
                setLoading(false);
            }
        };

        fetchHistory();
    }, [token]);

    const handleDownloadResponse = async (studyId) => {
        try {
            const response = await axios.get(
                `https://helikon.technology:5000/studies/${studyId}/response`,
                {
                    headers: { Authorization: `Bearer ${token}` },
                    responseType: 'blob',
                }
            );

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `response_${studyId}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Vastusefaili allalaadimine ebaõnnestus:', error);
            alert('Vastusefaili allalaadimine ebaõnnestus.');
        }
    };

    const formatDateTime = (datetime) => {
        const date = new Date(datetime);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    };

    // Filtreerimine otsingu ja uuringutüübi järgi
    const filteredHistory = history.filter(
        (item) =>
            (selectedStudyType === 'Kõik' || item.name === selectedStudyType) &&
            (item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                (item.patientName && item.patientName.toLowerCase().includes(searchQuery.toLowerCase())) ||
                (item.patientId && item.patientId.toLowerCase().includes(searchQuery.toLowerCase())))
    );

    // Lehekülgede jagamine
    const totalPages = Math.ceil(filteredHistory.length / itemsPerPage);
    const paginatedHistory = filteredHistory.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    if (loading) {
        return <p>Laen uuringute ajalugu...</p>;
    }

    if (error) {
        return <p className="error-message">{error}</p>;
    }

    return (
        <div className="upload-history-container">
            <h3>Üleslaaditud uuringud</h3>

            {/* Uuringutüüpide filter */}
            <div className="filter-container mb-3">
                <label htmlFor="studyTypeFilter" className="form-label">
                    Valige uuringutüüp:
                </label>
                <select
                    id="studyTypeFilter"
                    className="form-select"
                    value={selectedStudyType}
                    onChange={(e) => setSelectedStudyType(e.target.value)}
                >
                    {studyTypes.map((type) => (
                        <option key={type} value={type}>
                            {type}
                        </option>
                    ))}
                </select>
            </div>

            {/* Otsing */}
            <input
                type="text"
                placeholder="Otsi uuringu, patsiendi nime või isikukoodi järgi"
                className="form-control mb-3"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
            />

            {/* Tabel uuringute kuvamiseks */}
            {paginatedHistory.length === 0 ? (
                <p>Ajalugu puudub või otsing ei andnud tulemusi.</p>
            ) : (
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Uuring</th>
                            <th>Patsiendi nimi</th>
                            <th>Isikukood</th>
                            <th>Kuupäev</th>
                            <th>Tegevus</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paginatedHistory.map((upload) => (
                            <tr key={upload.id}>
                                <td>{upload.name}</td>
                                <td>{upload.patientName || 'Puudub'}</td>
                                <td>{upload.patientId || 'Puudub'}</td>
                                <td>{formatDateTime(upload.createdAt)}</td>
                                <td>
                                    {upload.responseFile ? (
                                        <button
                                            className="btn btn-link"
                                            onClick={() => handleDownloadResponse(upload.id)}
                                        >
                                            Laadi alla vastus
                                        </button>
                                    ) : (
                                        <span className="text-muted">Analüüsimisel</span>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}

            {/* Lehekülgede nupud */}
            {totalPages > 1 && (
                <nav className="pagination-container mt-3">
                    <ul className="pagination justify-content-center">
                        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                            <button
                                className="page-link"
                                onClick={() => handlePageChange(currentPage - 1)}
                            >
                                Eelmine
                            </button>
                        </li>
                        {Array.from({ length: totalPages }, (_, i) => (
                            <li
                                key={i + 1}
                                className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}
                            >
                                <button
                                    className="page-link"
                                    onClick={() => handlePageChange(i + 1)}
                                >
                                    {i + 1}
                                </button>
                            </li>
                        ))}
                        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                            <button
                                className="page-link"
                                onClick={() => handlePageChange(currentPage + 1)}
                            >
                                Järgmine
                            </button>
                        </li>
                    </ul>
                </nav>
            )}
        </div>
    );
}

export default UploadHistory;
