import React, { useState } from 'react';
import axios from 'axios';

function AddressAutocomplete({ onAddressSelect }) {
    const [query, setQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);

    const handleInputChange = async (e) => {
        const value = e.target.value;
        setQuery(value);
    
        if (value.length > 2) {
            try {
                const response = await axios.get('https://helikon.technology:5000/api/places', {
                    params: { input: value },
                });
                setSuggestions(response.data);
            } catch (error) {
                console.error('Aadressi otsingu viga:', error);
            }
        } else {
            setSuggestions([]);
        }
    };
    

    const handleSelect = (address) => {
        setQuery(address);
        setSuggestions([]);
        if (onAddressSelect) onAddressSelect(address);
    };

    return (
        <div className="autocomplete-container">
            <input
                type="text"
                className="form-control"
                placeholder="Sisesta aadress"
                value={query}
                onChange={handleInputChange}
            />
            {suggestions.length > 0 && (
                <ul className="list-group mt-2">
                    {suggestions.map((suggestion) => (
                        <li
                            key={suggestion.place_id}
                            className="list-group-item"
                            onClick={() => handleSelect(suggestion.description)}
                        >
                            {suggestion.description}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}

export default AddressAutocomplete;
